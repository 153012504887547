<template>
	<div
		class="tab"
		:class="{ active: props.active }"
	>
		<span>
			<Badge
				dot
				:visible="props?.showBadge || false"
			>
				<Icon
					v-if="props.icon"
					:name="props.icon"
					:filled="props.active"
				/>
			</Badge>
		</span>
		<span class="tab-content">{{ props?.label ? $t(props?.label) : "" }}</span>
	</div>
</template>

<script lang="ts" setup>
import Badge from "@/components/Badge.vue";
interface TabProps {
	icon?: string;
	label?: string;
	active: boolean;
	showBadge?: boolean;
}

const props = defineProps<TabProps>();
</script>

<style lang="scss" scoped>
.tab {
	@apply flex justify-center flex-col min-w-[70px] min-h-[56px] text-center cursor-pointer rounded-[4px];
	&.active,
	&.active:hover {
		@apply bg-black text-white;
	}
	&:hover {
		@apply bg-neutral-50;
	}
	.tab-content {
		@apply text-[11px] leading-[18px];
	}
}
</style>
