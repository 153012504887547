import { useI18n } from "vue-i18n";
import type { RootNavItem } from "@/components/Navigation/types";

export const useNavStore = defineStore("navigations", () => {
	const { t } = useI18n();
	const { isDeveloper, isTengivaUser } = storeToRefs(useUserStore());

	const routes = computed((): RootNavItem[] => [
		{
			id: "dashboard",
			icon: "team_dashboard",
			label: t("components.navigations.label.dashboard"),
			showBadge: false,
			path: "/",
			showOnTabbar: true,
			hidden: false,
		},
		{
			id: "devs",
			icon: "md-integration_instructions",
			label: "Devs",
			items: isDeveloper.value ? getDevNavItems() : [],
			showOnTabbar: false,
			showBadge: false,
			hidden: !isDeveloper.value,
		},
		{
			id: "kiosk",
			icon: "md-storefront",
			label: "Kiosk",
			path: "/kiosk",
			showOnTabbar: true,
			showBadge: false,
			hidden: false,
		},
		{
			id: "tengiva_admins",
			icon: "md-supervisor_account",
			label: "Tengiva Admin",
			showOnTabbar: false,
			items: [
				{
					id: "organizations",
					label: "Organizations",
					path: "/tengiva-admin/organizations",
				},
				{
					id: "services",
					label: "Services",
					path: "/tengiva-admin/services",
				},
				{
					id: "serviceSubscriptions",
					label: "Services subscriptions",
					path: "/tengiva-admin/service-subscriptions",
				},
				{
					id: "packages",
					label: "Packages",
					path: "/tengiva-admin/packages",
				},
				{
					id: "skus",
					label: "SKUs",
					path: "/tengiva-admin/digitization-skus",
				},
				{
					id: "requests",
					label: "Requests",
					path: "/tengiva-admin/digitization-requests",
				},
				{
					id: "deliveries",
					label: "Delivery",
					path: "/tengiva-admin/digitization-delivery",
				},
				{
					id: "queue",
					label: "Queue",
					path: "/tengiva-admin/digitization-queue",
				},
				{
					id: "workflow",
					label: "Workflow",
					path: "/tengiva-admin/digitization-workflow",
				},
				{
					id: "swatches",
					label: "Swatches",
					path: "/tengiva-admin/digitization-swatches",
				},
				{
					id: "covolt-access",
					label: "Covolt access",
					path: "/tengiva-admin/covolt-access",
				},
				{
					id: "my-team",
					label: "My team",
					path: "/tengiva-admin/my-team",
				},
				{
					id: "translation_management",
					label: t("components.navigations.label.translaion_management"),
					path: "/tengiva-admin/translation-management",
				},
				{
					id: "sample-orders",
					label: t("components.navigations.label.sample_orders"),
					path: "/tengiva-admin/sample-orders",
				},
			],
			hidden: !isTengivaUser.value,
		},
	]);

	const getDevNavItems = () => {
		return [
			{
				id: "sevs_apis_docs",
				label: "APIs Doc",
				path: "/api",
			},
			{
				id: "devs_pull_request",
				label: "Pull Requests",
				path: "/dev/pull_requests",
			},
			{
				id: "devs_test_users",
				label: "Test Users",
				path: "/dev/test_users",
			},
		];
	};

	return {
		routes,
	};
});
